import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Input,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import TodoListItem from './TodoListItem'
import { rdb } from '../firebase'
import ReactMarkdownChakra from '../helpers/ReactMarkdownChakra'

let todoRef = rdb.ref('todos')

const TodoList = (props) => {
  // TODO: add firebase realtime database integration here for realtime updating
  let [todoList, setTodoList] = useState({})
  let [inputTodo, setInputTodo] = useState('')

  useEffect(() => {
    todoRef.on('value', (snapshot) => {
      setTodoList(snapshot.val() ?? {})
    })
  }, [])

  const [deleteItemIdx, setDeleteItemIdx] = useState(undefined)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const inputColor = useColorModeValue('gray.800', 'gray.200')
  const inputBg = useColorModeValue('gray.100', 'gray.600')
  const inputFocusedBg = useColorModeValue('gray.200', 'gray.700')

  const toggleTodoListItem = async (idx) => {
    let todoListCpy = { ...todoList }
    todoListCpy[idx].done = !todoListCpy[idx].done
    await rdb.ref(`todos/${idx}`).update({ done: todoListCpy[idx].done })
    setTodoList(todoListCpy)
  }

  const addTodoListItem = async (content) => {
    rdb.ref(`todos/${Date.now()}`).set({
      content: content,
      done: false,
    })
  }

  const deleteTodoListItem = async (idx) => {
    let todoListCpy = { ...todoList }
    setDeleteItemIdx(undefined)
    delete todoListCpy[idx]
    await rdb.ref(`todos/${idx}`).remove()
    setTodoList(todoListCpy)
  }

  const confirmDeleteTodoListItem = (idx) => {
    setDeleteItemIdx(idx)
    onOpen()
  }

  return (
    <Box
      boxShadow={['none', 'sm', '2xl']}
      rounded="xl"
      p={[2, 4, 10]}
      marginY={[2, 4, 16]}
      marginX={[0, 2, 10]}
    >
      <Stack spacing={2} direction="row" w={'full'}>
        <Input
          type={'text'}
          placeholder={'Something cool!'}
          color={inputColor}
          bg={inputBg}
          rounded={'full'}
          border={0}
          _focus={{
            bg: inputFocusedBg,
            outline: 'none',
          }}
          onChange={(evt) => {
            setInputTodo(evt.target.value)
          }}
          value={inputTodo}
        />
        <IconButton
          aria-label="Add item"
          icon={<AddIcon />}
          bg="blue.400"
          rounded="full"
          color="white"
          _hover={{ bg: 'blue.500' }}
          _focus={{ bg: 'blue.500' }}
          _active={{ bg: 'blue.600' }}
          onClick={() => {
            if (inputTodo !== '') {
              addTodoListItem(inputTodo)
              setInputTodo('')
            }
          }}
        />
      </Stack>

      {Object.values(todoList).map((todoItem, idx) => (
        <TodoListItem
          isDone={todoItem.done}
          content={todoItem.content}
          updateDone={async () => {
            // console.log(`Update done for item ${idx}`)
            await toggleTodoListItem(Object.keys(todoList)[idx])
          }}
          onDelete={() => {
            // console.log(`Trying to delete todo #${idx}`)
            confirmDeleteTodoListItem(Object.keys(todoList)[idx])
          }}
          key={idx}
        />
      ))}

      <Modal
        onClose={() => {
          setDeleteItemIdx(undefined)
          onClose()
        }}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Truly delete this item? 🥺</ModalHeader>
          <ModalCloseButton />
          <ModalBody as="i">
            <ReactMarkdownChakra
              content={
                deleteItemIdx !== undefined
                  ? todoList[deleteItemIdx].content
                  : ''
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={async () => {
                onClose()
                await deleteTodoListItem(deleteItemIdx)
              }}
              colorScheme="red"
              mr={3}
            >
              Delete
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default TodoList
