import React from 'react'
import { Box, HStack, IconButton, useColorModeValue } from '@chakra-ui/react'
import { CheckIcon, DeleteIcon, CloseIcon } from '@chakra-ui/icons'
import ReactMarkdownChakra from '../helpers/ReactMarkdownChakra'

const TodoListItem = ({ isDone = false, content, updateDone, onDelete }) => {
  const checkColor = useColorModeValue('green.100', 'green.900')
  const closeColor = useColorModeValue('red.100', 'red.900')
  const todoTextColor = useColorModeValue('gray.800', 'whiteAlpha.900')

  return (
    <Box boxShadow="base" rounded="xl" p="2" marginTop="4" textAlign="left">
      <HStack textAlign="left">
        <IconButton
          icon={isDone ? <CloseIcon /> : <CheckIcon />}
          color={isDone ? 'red.400' : 'green.400'}
          rounded="full"
          variant="ghost"
          size="md"
          _hover={{ bg: isDone ? closeColor : checkColor }}
          onClick={() => {
            updateDone()
          }}
          borderColor={isDone ? 'red.400' : 'green.400'}
          // _focus={{ bg: 'red.500' }}
          // _active={{ bg: 'red.600' }}
        />
        <Box
          paddingX="2"
          fontSize={['sm', 'md', 'xl']}
          textDecoration={isDone ? 'line-through wavy' : 'none'}
          color={isDone ? 'gray.500' : todoTextColor}
          _hover={{ textDecoration: isDone ? 'line-through' : 'none' }}
          flexGrow={'1'}
        >
          <ReactMarkdownChakra content={content} />
        </Box>
        <IconButton
          icon={<DeleteIcon />}
          color="red.400"
          rounded="full"
          variant="ghost"
          size="md"
          onClick={onDelete}
        />
      </HStack>
    </Box>
  )
}

export default TodoListItem
