import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'

import firebaseConfig from './firebase.config.json'

firebase.initializeApp(firebaseConfig)
const rdb = firebase.database()
const db = firebase.firestore()

export { rdb, db }
