import '@fontsource/rubik'
import '@fontsource/poppins'
import React from 'react'
import { ChakraProvider, Box, Grid, Container } from '@chakra-ui/react'
import { ColorModeSwitcher } from './ColorModeSwitcher'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import theme from './theme'
import Home from './pages/Home'

function App() {
  const history = createBrowserHistory()
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <Container maxW="container.xl" p={[0, 2, 8]}>
            <Router history={history}>
              <Switch>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </Router>
          </Container>
        </Grid>
      </Box>
    </ChakraProvider>
  )
}

export default App
