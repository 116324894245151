import React, { useState } from 'react'
import {
  Collapse,
  Heading,
  ScaleFade,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { withRouter } from 'react-router-dom'
import TodoList from '../components/TodoList'

const Home = (props) => {
  let [doneLoading, setDoneLoading] = useState(false)
  let [showInitial, setShowInitial] = useState(false)
  let [showNext, setShowNext] = useState(false)

  if (!doneLoading) {
    setTimeout(() => {
      setDoneLoading(true)
      setShowInitial(true)
      setTimeout(() => {
        setShowNext(true)
      }, 2000)
    }, 2000)
  }

  return (
    <VStack spacing={8} h="full" w="full">
      {doneLoading ? (
        <>
          {showInitial ? (
            <ScaleFade
              in={showInitial}
              style={{ width: '100%', height: '100%' }}
            >
              <Heading fontSize="3xl">To-do Adventures</Heading>
              <Collapse in={showNext} style={{ width: '100%' }}>
                <TodoList />
              </Collapse>
              <Text>Made with ❤️ for Mahek by Sid</Text>
            </ScaleFade>
          ) : null}
        </>
      ) : (
        <Spinner size="xl" />
      )}
    </VStack>
  )
}

export default withRouter(Home)
