import React from 'react'
import { Link } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'

const newTheme = {
  a: (props) => {
    const { href, title } = props
    return (
      <Link color="blue.400" href={href}>
        {title ? title : href}
      </Link>
    )
  },
}

const ReactMarkdownChakra = ({ content }) => {
  return (
    <ReactMarkdown components={ChakraUIRenderer(newTheme)} children={content} />
  )
}

export default ReactMarkdownChakra
